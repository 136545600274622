import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import { rhythm, scale } from "../utils/typography";

class TitleCarosel extends React.Component {
  options = ["developer", "teacher", "wannabe designer", "learner", "writer", "backpacker"];
  state = { count: 0 };
  intervalId = null;

  componentDidMount() {
    if (this.intervalId) clearInterval(this.intervalId);
    this.intervalId = setInterval(this.tick, 1500);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  tick = () => {
    this.setState(p => ({ count: p.count + 1 }));
  };

  render() {
    const title = this.options[this.state.count % this.options.length];
    return <span style={{ borderBottom: "3px solid #FF5C51" }}>{title}</span>;
  }
}

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata;
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(2)
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                width: rhythm(2),
                height: rhythm(2),
                minWidth: rhythm(2),
                marginBottom: 0,
                borderRadius: `100%`
              }}
              imgStyle={{
                borderRadius: `50%`
              }}
            />
            <p>
              Hi! I'm a <TitleCarosel /> from Sydney, Australia <span style={{ ...scale(0.1)}}>🇦🇺</span><br/>
              Find me on <a href={`https://twitter.com/${social.twitter}`}>Twitter</a> or <a href={`https://github.com/${social.github}`}>Github</a>
            </p>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          github
        }
      }
    }
  }
`;

export default Bio;
